<template>
    <div
        class="dashboard-container"
        :class="{ 'section-show': hasSectionClass }"
    >
        <DashboardPerformanceChart mode="self" />
        <DashboardPerformanceChart mode="org" />
        <div class="gradient-line" />
    </div>
</template>

<script>
// import _ from 'lodash'
import DashboardPerformanceChart from '@/containers/home/DashboardPerformanceChart.vue'

export default {
    name: 'DashboardPerformance',
    components: {
        DashboardPerformanceChart
    },
    computed: {
        isSectionShow: function () {
            return !this.$store.state.isSectionHidden
        },
        performanceData: function () {
            return this.$store.state.dashboardPerformance
        }
    },
    watch: {
        isSectionShow: function (newVal) {
            //解決section CSS動畫的延遲造成跑版
            if (newVal) {
                this.hasSectionClass = this.isSectionShow
            } else {
                setTimeout(() => {
                    this.hasSectionClass = this.isSectionShow
                }, 400)
            }
        }
    },
    data() {
        return {
            hasSectionClass: true
        }
    }
}
</script>
<style lang="scss" scoped>
.dashboard-container {
    padding: 0 20px;
    &.section-show {
        padding: 0 10px;
    }
    @media (min-width: 1018px) and (max-width: 1060px) {
        padding: 0 10px;
    }
    @media (min-width: 910px) and (max-width: 1017px) {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        padding: 0;
    }
    @media screen and (max-width: 909px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        padding: 0 10px;
    }
}

:deep(.performance-container) {
    margin: 10px 0 35px;
    &:first-of-type {
        margin-bottom: 0;
        padding-bottom: 35px;
        border-bottom: 1px solid $sum-row-grey;
        margin-right: 0px;
    }
    &:first-child {
        margin-right: 0;
    }

    @media (min-width: 910px) and (max-width: 1017px) {
        margin: 0;
        padding: 10px 20px;
        border-radius: 10px;
        background-color: $primary-white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        width: 100%;
        &:first-child {
            margin-right: 10px;
        }
    }
    @media (min-width: 577px) and (max-width: 909px) {
        max-width: 400px;
    }
    @media screen and (max-width: 576px) {
        max-width: 400px;
        width: 100%;
        margin: 10px 0 20px;
        &:first-of-type {
            padding-bottom: 20px;
        }
    }
}

.gradient-line {
    margin-bottom: 10px;
    height: 11px;
    background: $dashboard-gradient;
    border-radius: 8px;

    @media (min-width: 910px) and (max-width: 1017px) {
        display: none;
    }
    @media screen and (max-width: 909px) {
        max-width: 400px;
        width: 100%;
    }
}
</style>
